import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ChevronRight } from 'app/components/Icons'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  icon?: string
  image?: ImageProps
  label?: string
  URL?: string
}

export const Service = memo(function Service({
  icon,
  image,
  label,
  URL,
}: Props) {
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  const componentDOM = (
    <>
      {image ? (
        <LazyLoadComponent>
          <Image className="service-image" {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper className="service-wrapper">
        {icon ? <Icon alt={label} src={icon} width="40" height="40" /> : null}
        {label ? (
          <Label
            dangerouslySetInnerHTML={{ __html: label.replace(' ', '<br />') }}
          />
        ) : null}
        <ChevronRight />
      </Wrapper>
    </>
  )

  return (
    <>
      {URL ? (
        externalURL ? (
          <ExternalLink
            className={!image ? 'no-image' : undefined}
            href={URL}
            rel="noopener"
            target="_blank"
          >
            {componentDOM}
          </ExternalLink>
        ) : (
          <Container className={!image ? 'no-image' : undefined} to={URL || ''}>
            {componentDOM}
          </Container>
        )
      ) : (
        <Static className={!image ? 'no-image' : undefined}>
          {componentDOM}
        </Static>
      )}
    </>
  )
})

const Style = css`
  width: calc(33.333% - 11.875vw);
  border-radius: 50%;
  overflow: hidden;
  margin: 7.0625rem 11.875vw 0 0;
  padding-bottom: calc(33.333% - 11.875vw);
  position: relative;
  transition: 0.3s ease-in-out;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  &.no-image {
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      border: 0.625rem solid ${theme.colors.variants.neutralDark2};
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:hover {
      background: ${theme.colors.variants.neutralDark2};
      .service-wrapper {
        span {
          color: ${theme.colors.variants.neutralLight4};
        }
        img {
          filter: invert(1) contrast(2);
        }
      }
    }
    span {
      color: ${theme.colors.variants.neutralDark2};
    }
  }
  &:hover {
    .service-image {
      img {
        transform: scale(1.1);
      }
    }
    .service-wrapper {
      top: 50%;
      svg {
        opacity: 1;
      }
    }
  }

  .service-image {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      background: ${theme.colors.variants.neutralDark1};
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.25s ease-in-out !important;
    }
  }

  @media (max-width: 1023px) {
    display: block;
    width: 26.8vh;
    max-width: 15rem;
    height: 26.8vh;
    margin: 2.5rem auto 0;
    padding-bottom: 0;
  }
`

const Static = styled.a`
  ${Style}
`

const ExternalLink = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`

const Wrapper = styled.div`
  padding: 0 1.875rem;
  position: absolute;
  top: calc(50% + 1rem);
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 2;

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    opacity: 0;
    margin-top: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 3;
    transition: 0.2s ease-in-out;
  }
`

const Icon = styled.img`
  display: block;
  width: auto;
  margin: 0 auto 1.5625rem;
  transition: 0.2s ease-in-out;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3125rem;
  transition: 0.3s ease-in-out;

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
  }
`
