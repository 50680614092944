import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  images?: ImageProps[]
  subtitle?: string
  title?: string
  variant?: Variant
}

export const Visual = memo(function Visual({
  cta,
  images,
  subtitle,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container row tag="section" stretch wrap variant={variant}>
      <LeftSide>
        {images ? (
          images[0] ? (
            <LazyLoadComponent>
              <Image {...images[0]} />
            </LazyLoadComponent>
          ) : null
        ) : null}
      </LeftSide>

      <RightSide>
        <Thumbs row space="between" wrap>
          {images ? (
            <>
              {images[1] ? (
                <Thumb>
                  <LazyLoadComponent>
                    <Image {...images[1]} />
                  </LazyLoadComponent>
                </Thumb>
              ) : null}
              {images[2] ? (
                <Thumb>
                  <LazyLoadComponent>
                    <Image {...images[2]} />
                  </LazyLoadComponent>
                </Thumb>
              ) : null}
            </>
          ) : null}
        </Thumbs>

        <Wrapper className="visual-wrapper">
          {title ? (
            <FadeInUp>
              <Line variant="invert" />
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {subtitle ? (
            <FadeInUp>
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <CTA variant="invert" {...cta} />
            </FadeInUp>
          ) : null}
        </Wrapper>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  margin-top: 12.5rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'invert':
        return css`
          > div {
            &:first-of-type {
              order: 2;
            }
            &:last-of-type {
              padding-right: 7.778vw;
              padding-left: 0;
            }
          }
          .visual-wrapper {
            margin-left: 16.806vw;
          }

          @media (max-width: 1023px) {
            > div {
              &:last-of-type {
                padding-right: 1.3125rem;
                padding-left: 1.3125rem;
              }
            }
            .visual-wrapper {
              margin-left: 0;
            }
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    margin-top: 9.6875rem;
  }
`

const LeftSide = styled.div`
  width: 33.5%;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding-bottom: 63%;
  }
`

const RightSide = styled.div`
  width: 66.5%;
  padding-left: 7.778vw;

  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 3.75rem 1.3125rem 1.3125rem;
  }
`

const Thumbs = styled(FlexBox)`
  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    margin-top: 3.75rem;
  }
`

const Thumb = styled.div`
  width: calc(50% - 3.889vw);
  padding-bottom: calc(50% - 3.889vw);
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  position: relative;

  @media (max-width: 1023px) {
    width: calc(50% - 0.75rem);
    padding-bottom: calc(50% - 0.75rem);
  }
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 12.3125rem 0 0 9.722vw;
  padding-bottom: 8.75rem;

  @media (max-width: 1023px) {
    margin: 0;
    padding: 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.375rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'invert'
