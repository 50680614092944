import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const IconServices = memo(function IconServices({
  services,
  subtitle,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}

      <Wrapper row wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 14.375rem;
  padding: 0 10vw;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 7.875rem;
    margin-bottom: 8.3125rem;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 4.25rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  letter-spacing: 0.1125rem;
  line-height: 1.3125rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    letter-spacing: 0.0938rem;
    line-height: 1.1875rem;
    margin-top: 0.375rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -11.875vw;

  @media (max-width: 1023px) {
    display: block;
    margin-right: 0;
  }
`
