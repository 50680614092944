import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import React, { memo } from 'react'

export interface Props {
  className?: string
  cta?: ButtonProps
  image?: ImageProps
  isLoaded?: boolean
  style?: any
  title: string
}

export const Service = memo(function Service({
  className,
  cta,
  image,
  isLoaded,
  style,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container className={className}>
      <Inner style={style}>
        {isLoaded && image ? (
          <Image media="(min-width: 2800px)" {...image} />
        ) : (
          <Spinner variant="simple" />
        )}
      </Inner>

      <Wrapper style={style}>
        <Title>{title}</Title>
        {cta ? <CTA variant="invert" {...cta} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  overflow: hidden;
  position: relative;
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 5rem;
  left: 6.944vw;
  z-index: 2;

  @media (max-width: 1023px) {
    bottom: 5.3125rem;
    right: 1.3125rem;
    left: 1.3125rem;
    text-align: center;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 0.75rem;
  }
`
