import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Scroll } from 'app/components/Common/Button/Scroll'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  claim?: string
  images?: ImageProps[]
  mobileImage?: ImageProps
  payoff?: string
}

export const Hero = memo(function Hero({ claim, images, payoff }: Props) {
  if (!images) {
    return null
  }

  if (images.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [details, setDetails] = useState<any>(null)
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2000,
    },
    drag: false,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <>
      <Container>
        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide className="keen-slider__slide" key={index}>
              <Inner style={positionStyle(index)}>
                {loaded[index] ? (
                  <Image
                    {...item}
                    alt={loaded[index] ? item.alt : undefined}
                    media="(min-width: 2800px)"
                  />
                ) : (
                  <Spinner variant="simple" />
                )}
              </Inner>
            </Slide>
          ))}
        </Slider>

        <Wrapper>
          {payoff ? <Payoff>{payoff}</Payoff> : null}
          {claim ? <Claim>{claim}</Claim> : null}

          {images.length > 1 ? (
            <Dots dial={4} row>
              {images.map((_item, index) => (
                <Dot
                  key={index}
                  className={currentSlide === index ? 'active' : undefined}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(index)
                  }}
                />
              ))}
            </Dots>
          ) : null}
        </Wrapper>

        <Scroll />
      </Container>
    </>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  position: relative;
`

const Slider = styled.div`
  display: flex;
  height: calc(100% - 18.75rem);
  overflow: hidden;
  position: absolute;
  top: 11.5rem;
  right: 0;
  left: 23.611vw;
  z-index: 2;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    height: calc(100% - 27rem);
    top: 17.5rem;
    left: 1.3125rem;
  }
`

const Slide = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  max-width: 37.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: 50%;
  left: 10vw;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1023px) {
    max-width: none;
    height: calc(100% - 8.4375rem);
    top: 8.4375rem;
    right: 1.3125rem;
    left: 1.3125rem;
    transform: none;
  }
`

const Payoff = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-bottom: 0.625rem;
  }
`

const Claim = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.0625rem;
  line-height: 4.6875rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Dots = styled(FlexBox)`
  height: 0.75rem;
  margin-top: 4.25rem;

  @media (max-width: 1023px) {
    margin-top: 0;
    position: absolute;
    bottom: 6.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  margin-right: 1.5rem;
  transition: 0.3s ease-in-out;
  &.active {
    width: 0.75rem;
    height: 0.75rem;
    background: transparent;
    border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
    pointer-events: none;
  }

  @media (max-width: 1023px) {
    margin: 0 0.75rem;
  }
`
