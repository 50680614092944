import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as ServiceProps, Service } from './Service'

const AUTOPLAY_TIMER = 5000

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2000,
    },
    drag: false,
    loop: services.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      services.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {title ? (
          <FadeInUp>
            <Line />
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        <Slider ref={sliderRef}>
          {services.map((item, index) => (
            <Service
              className="keen-slider__slide"
              key={index}
              isLoaded={loaded[index]}
              style={positionStyle(index)}
              {...item}
            />
          ))}
        </Slider>

        {services.length > 1 ? (
          <>
            <Media greaterThanOrEqual="ipadHorizontal">
              <Arrows row>
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                />
                <Arrow
                  direction="R"
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                />
              </Arrows>
            </Media>

            <Media lessThan="ipadHorizontal">
              <Dots dial={4} row>
                {services.map((_item, index) => (
                  <Dot
                    key={index}
                    className={currentSlide === index ? 'active' : undefined}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(index)
                    }}
                  />
                ))}
              </Dots>
            </Media>
          </>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto;
  padding: 0 10vw;
  position: relative;

  @media (max-width: 1023px) {
    margin-top: 5.3125rem;
    margin-bottom: 8.5rem;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.0625rem;
  line-height: 4.6875rem;
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
    margin-bottom: 1rem;
  }
`

const Slider = styled.div`
  display: flex;
  height: 71.1vh;
  max-height: 40rem;
  overflow: hidden;
  outline: none;

  @media (max-width: 1023px) {
    height: 44.7vh;
    max-height: 25rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 5rem;
  right: 16.944vw;
`

const Dots = styled(FlexBox)`
  height: 0.75rem;
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  margin: 0 0.75rem;
  transition: 0.3s ease-in-out;
  &.active {
    width: 0.75rem;
    height: 0.75rem;
    background: transparent;
    border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
    pointer-events: none;
  }
`
